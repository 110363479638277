import * as React from "react";
import { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "../link";
import { useUser } from "../../hooks/useUser";
import { Box, Button, ListItem, ListItemText } from "@mui/material";
import Logout from "../Logout";
import useLogout from "../../hooks/useLogout.tsx";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

interface NavItem {
  name: string;
  link: string;
  condition: boolean;
}

export default function LandingHeader(props: Props) {
  const user = useUser();
  const [navItems, setNavItems] = React.useState<NavItem[]>([]);
  const logout = useLogout();

  useEffect(() => {
    setNavItems([
      {
        name: "Home",
        link: "/",
        condition: true,
      },
      {
        name: "About",
        link: "/about",
        condition: true,
      },
      {
        name: "Contact",
        link: "/contact",
        condition: true,
      },
      {
        name: "Go To App",
        link: "/app/news",
        condition: !!user,
      },
      {
        name: "Login",
        link: "/login",
        condition: !user,
      },
      /*{
        name: "Sign Up",
        link: "/signup",
        condition: !user,
      }*/
    ]);
  }, [user]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        GEOSTRAT
      </Typography>
      <Divider />
      <List>
        {navItems
          .filter((item) => item.condition)
          .map((item) => (
            <ListItem key={item.name} disablePadding>
              <Link href={item.link} key={item.link} style={{ width: "100%" }}>
                <ListItemButton sx={{ textAlign: "left" }}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        {user && (
          <ListItem disablePadding>
            <Link
              style={{ width: "100%" }}
              onClick={() => {
                logout();
              }}
            >
              <ListItemButton sx={{ textAlign: "left" }}>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </Link>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "64px" }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            GeoStrat
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems
              .filter((item) => item.condition)
              .map((item) => (
                <Link href={item.link} key={item.link}>
                  <Button key={item.name} sx={{ color: "#fff" }}>
                    {item.name}
                  </Button>
                </Link>
              ))}
            {user && (
              <Link
                onClick={() => {
                  logout();
                }}
              >
                <Button sx={{ color: "#fff" }}>Log Out</Button>
              </Link>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
