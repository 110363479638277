import {Button, Tooltip, useTheme} from "@mui/material";
import React from "react";

// A button with a tooltip
export default function GeoButton({
  title,
  children,
    variant,
  ...props
}: any) {
  const theme = useTheme();

  return (
    <Tooltip enterTouchDelay={0} title={title}>
      <span style={{ display: "inline-block", cursor: "pointer" }}>
        <Button variant={variant || "outlined"} sx={{
          height: "100%",
          "&.Mui-disabled": {
            color: theme.palette.primary.main
          }
        }} {...props}>
          {children}
        </Button>
      </span>
    </Tooltip>
  );
}
