/** @jsxImportSource @emotion/react */
import {
  AppBar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import Link from "../link";
import AdminHeader from "../AdminHeader";
import NotificationsDropdown from "./Notifications";
import AccountMenu from "./UserMenu";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import ClassSwitcher from "../ClassSwitcher";
import ClassDisplay from "../ClassDisplay";

export default function Header() {
  const navigate = useNavigate();
  const drawerWidth = 240;
  const isAuthenticated = true;
  const user = useUser();
  const linkSx = { textDecoration: "none", width: "100%" };
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const pages = [
    {
      text: "News",
      link: "/app/news",
      condition: true,
    },
    {
      text: "Nations",
      link: "/app/nations",
      condition: true,
    },
    {
      text: "Diplomacy",
      link: "/app/diplomacy",
      condition: true,
    },
    {
      text: "Trade",
      link: "/app/trade",
      condition: true,
    },
    {
      text: "Development",
      link: "/app/development",
      condition: true,
    },
    {
      text: "Map",
      link: "/app/map",
      condition: true,
    },
    // {
    //   text: "Community",
    //   link: "/app/community",
    //   condition: true,
    // },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        GeoStrat
      </Typography>
      <Divider />
      <List>
        {pages
          .filter((item) => item.condition)
          .map((item) => (
            <ListItem key={item.text} disablePadding>
              <Link href={item.link} style={linkSx} key={item.link}>
                <ListItemButton sx={{ textAlign: "left" }}>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  const container =
    typeof window !== "undefined" ? () => window.document.body : undefined;

  if (!user) {
    return <></>;
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Box sx={{ display: "flex", height: "64px" }}>
        <AppBar position="fixed" component="nav" sx={{ height: "64px" }}>
          <Toolbar sx={{ height: "64px", paddingLeft: "5px!important" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" }, paddingLeft: "24px" }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              <>
                {pages
                  .filter((item) => item.condition)
                  .map((page) => (
                    <Link href={page.link} style={linkSx} key={page.link}>
                      <Button variant="contained" sx={{ boxShadow: "none" }} tabIndex={-1}>
                        {page.text}
                      </Button>
                    </Link>
                  ))}
              </>
            </Box>
            <Box
              sx={{
                flex: 1,
                textAlign: "right",
              }}
            >
              {/*{*/}
              {/*  user && user.isAdmin && <ClassSwitcher />*/}
              {/*}*/}
              {user && user.isAdmin && (<>
                <Button variant="contained" sx={{ boxShadow: "none", display: { xs: 'none', sm: 'inline-block' } }} onClick={toggleMenu}>
                  {user.className.name}: {user.nation.name}
                </Button>
                <Button variant="contained" sx={{ boxShadow: "none", display: { xs: 'inline-block', sm: 'none' } }} onClick={toggleMenu}>
                  {user.className.name}
                </Button>
                  </>
              )}

              <NotificationsDropdown />

              <AccountMenu />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      {
        user && user.isAdmin && <AdminHeader menuOpen={menuOpen} />
      }

    </>
  );
}
