/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useSWR from "swr";
import NewArticle from "./newArticle";
import { Article } from "../../my-api-client";
import { apiClient } from "../../services/apiClient";
import ArticleComponent from "./Article";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useUser} from "../../hooks/useUser.ts";
import LoadingScreen from "../Loading/loadingScreen.tsx";

export default function News() {
  const user = useUser();
  const demoArticle: Article = {
    id: 0,
    title: "Welcome to Geostrat!",
    content: `You're currently viewing a sandbox environment that you can explore and use for testing purposes.  Feel free to take a look around.
                    
When you're ready to setup your first class there is a <a href="/app/getting_started">Getting Started</a> guide available in the user menu. If you have any questions or need help, please feel free to reach out using the chat available in the bottom right corner.`,
    createdAt: new Date().toString(),
    createdBy: {
      id: 1,
      fullName: "Geostrat",
      imageUrl: "",
    },
    comments: [],
    classNameId: 1
  };

  const { data, error, isLoading } = useSWR<Article[], Error>(
    apiClient().allArticlesList.name,
    function () {
      return apiClient()
        .allArticlesList()
        .then((res) => {
          return res.data;
        });
    },
  );

  if (error) return "Error loading posts.";

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding-bottom: 20px;
        padding-left: 5px;
        padding-right: 5px;
      `}
    >
      <div
        css={css`
          max-width: 100%;
          width: 800px;
          display: flex;
          flex-direction: column;
        `}
      >
        <NewArticle ogTitle="" ogContent="" />
        {
            isLoading && <LoadingScreen />
        }
        {data && (
          <>
            <Typography
              component="h1"
              variant="h5"
              css={css`
                text-align: center;
                margin-top: 20px;
              `}
            >
              {data.length === 0 && "No Articles Yet"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {data.map((article) => {
                return <ArticleComponent article={article} key={article.id} />;
              })}
              {
                  user.isAdmin && user.className.isDemoClass && <ArticleComponent article={demoArticle} key={demoArticle.id} />
              }
            </Box>
          </>
        )}
      </div>
    </div>
  );
}
