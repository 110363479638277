// @ts-nocheck
import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useScript from "../hooks/useScript";
import WelcomePage from "../components/Waitlist";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function Landing(props: Props) {
    useScript("https://js.stripe.com/v3/pricing-table.js"); // Previously this was in index.html.  It doesn't really save much by being here instead

  return (
      <WelcomePage />
    // <Container component="main" maxWidth="xl">
    //   <Typography>
    //
    //     {/*A tool used alongside a geography class that
    //       provides an interactive learning experience. Students are grouped into
    //       teams and work together to build their nations.*/}
    //   </Typography>
    //   {import.meta.env.DEV ? (
    //     <stripe-pricing-table
    //       pricing-table-id="prctbl_1OJvF6E9KVi5x0lByKboJFWB"
    //       publishable-key="pk_test_51OJrAnE9KVi5x0lBCq2McULYqfU76eKQs6zM3Stu6iYJwfRF8Ge7YQxXuMY9yrbaygy6KbWXlmHzAFvGwP6ihetH00YQjFDTp6"
    //     ></stripe-pricing-table>
    //   ) : (
    //       <>Sign up coming soon</>
    //     // <stripe-pricing-table
    //     //   pricing-table-id="prctbl_1OJs28E9KVi5x0lBdb9zfFHs"
    //     //   publishable-key="pk_live_51OJrAnE9KVi5x0lBEFr40AOUlV35aijQaRfG6kdgI1dKmEF8uwmSxvFfr4tq2yemcVR295PzKR18ev1Eswj2GPxz00cW9PZVmO"
    //     // ></stripe-pricing-table>
    //   )}
    // </Container>
  );
}
