import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Typography,
  Box,
} from "@mui/material";
import { NationStat, PowerCard } from "../../my-api-client";
import GeoWrapperTooltip from "../GeoWrapperTooltip";

interface NationStatsDisplayProps {
  nationStat: NationStat;
}

// Main Component to Display Nation Stats as List
const NationPowerCardsDisplay: React.FC<NationStatsDisplayProps> = ({
  nationStat,
}) => {
  return (
      <>
    <List>
      {nationStat.powerCards?.map((powerCard, index) => (
          <GeoWrapperTooltip key={index} title={powerCard.description} placement="top">
            <ListItem divider sx={{ justifyContent: "space-between" }}>
              <ListItemText primary={powerCard.name + ': ' + powerCard.value} />
            </ListItem>
          </GeoWrapperTooltip>
      ))}
    </List>
      </>
  );
};

export default NationPowerCardsDisplay;
