import * as React from "react";
import Container from "@mui/material/Container";

function Privacy() {
  return (
    <Container
      sx={{
        maxWidth: "1400px",
        padding: "20px",
      }}
    >
      <h1>Privacy Policy for Geostratapp.com</h1>
      <p>
        <strong>Last Updated:</strong> 12/14/2023
      </p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Geostratapp.com. We are committed to protecting your privacy.
        This Privacy Policy outlines the types of information we collect from
        our users, how we use it, and the measures we take to protect it.
      </p>

      <h2>2. Information We Collect</h2>
      <h3>a. Personal Information</h3>
      <p>
        This may include your name, email address, and other information you
        provide when you register, make a purchase, or interact with our
        website.
      </p>
      <h3>b. Non-Personal Information</h3>
      <p>
        We may also collect non-personal information such as your browser type,
        the date and time of your visit, and the pages you accessed on our
        website.
        <br/>
        We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the Microsoft Privacy Statement.
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>
        Your information is used to provide, maintain, and improve our services,
        to communicate with you, and to comply with legal obligations.
      </p>

      <h2>4. Sharing of Information</h2>
      <p>
        We do not sell, trade, or rent your personal identification information
        to others. We may share generic aggregated demographic information not
        linked to any personal identification information regarding visitors and
        users with our business partners, trusted affiliates, and advertisers.
      </p>

      <h2>5. Security</h2>
      <p>
        We adopt appropriate data collection, storage, and processing practices
        and security measures to protect against unauthorized access,
        alteration, disclosure, or destruction of your personal information.
      </p>

      <h2>6. Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal data.
        Please contact us to make such requests.
      </p>

      <h2>7. Third-Party Websites</h2>
      <p>
        Users may find advertising or other content on our Site that links to
        the sites and services of our partners, suppliers, advertisers,
        sponsors, licensors, and other third parties. We do not control the
        content or links that appear on these sites.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        Geostratapp.com has the discretion to update this privacy policy at any
        time. When we do, we will revise the updated date at the top of this
        page.
      </p>

      <h2>9. Contacting Us</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this site, please contact us at
        hello@geostratapp.com.
      </p>
    </Container>
  );
}

export default Privacy;
