/** @jsxImportSource @emotion/react */
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { apiClient } from "../../services/apiClient";
import { Nations, Purchase } from "../../my-api-client";
import Grid from "@mui/material/Unstable_Grid2";
import GeoTooltip from "../GeoTooltip";
import { permissionService } from "../../services/permissionService";
import {mutatePurchase, myMutate, useUser} from "../../hooks/useUser";
import React, { ReactElement, useEffect, useState } from "react";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import StoreContent from "./storeContent.tsx";
import useSWR, {Fetcher, mutate} from "swr";
import GeoButton from "../GeoButton";

interface ItemInputProps {
  purchase: Purchase;
}

export default function PurchaseCard({ purchase }: ItemInputProps) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [canAfford, setCanAfford] = useState(false);

  const { data, error } = useSWR<Nations, Error>(
    apiClient().allNationsRetrieve.name + user.nation.id,
    () => {
      return apiClient()
        .allNationsRetrieve(user.nation.id)
        .then((res) => {
          return res.data;
        });
    },
  );

  useEffect(() => {
    if (data) {
        const result = data.resources?.every((resource) => {
            // @ts-ignore
            if (purchase.item.itemPrice[resource.resourceType.name.toLowerCase() + 'Cost'] == 0) {
                return true;
            }
            // @ts-ignore
            if (resource.total < purchase.item.itemPrice[resource.resourceType.name.toLowerCase() + 'Cost']) {
              return false;
            }
            return true;
        });
      setCanAfford(result);
    } else {
      setCanAfford(false);
    }
  }, [data, purchase]);

  const approve = async (purchase: Purchase) => {
    const snack = enqueueSnackbar(
        <>
          Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <CircularProgress/>
        </>,
        {
          variant: "info",
          persist: true,
        },
    );
    setSaving(true);
    apiClient()
        .allPurchasesSenderAcceptCreate(purchase.id)
        .then((res) => {
          enqueueSnackbar("Purchase Approved", {variant: "success"});
          mutatePurchase(apiClient().allPurchasesList.name, res.data);
          return res.data;
        })
        .catch((e) => {
          enqueueSnackbar("Error approving purchase: " + e.toString(), {
            variant: "error",
          });
        })
        .finally(() => {
          setSaving(false);
          closeSnackbar(snack);
        });
  };

  const statusMap: { [name: string]: ReactElement } = {
    CREATED: <>Proposed, Awaiting Approval</>,
    SENDER_ACCEPTED: <>Approved</>,
    SENDER_REJECTED: <>Rejected</>,
    SENDER_CANCELED: <>Canceled</>,
    PURCHASE_PLACED: <>Build Completed</>,
    PURCHASE_DESTROYED: <>Destroyed</>,
  };

  

  const decline = (purchase: Purchase) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    apiClient()
      .allPurchasesSenderDeclineCreate(purchase.id)
      .then((res) => {
        enqueueSnackbar("Purchase Declined", { variant: "success" });
        mutatePurchase(apiClient().allPurchasesList.name, res.data);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error declining trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  return (
    <Grid  xs={12} sm={6} md={4} lg={3}  key={purchase.id}>
      <Card>
        <StoreContent item={purchase.item} />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            Status: {statusMap[purchase.status!]}
          </Typography>
        </CardContent>
        {purchase.status === "CREATED" && (
          <CardActions>
            <GeoButton
              variant={"outlined"}
              size="small"
              color="primary"
              title={
                permissionService.canPurchaseItem(user, purchase.item)
                  .message ||
                (!canAfford
                  ? "Not enough resources"
                  : purchase.item.isMilitary
                  ? permissionService.canApproveMilitary(
                      user,
                      purchase.createdBy!,
                    ).message
                  : permissionService.canApproveInfrastructure(
                      user,
                      purchase.createdBy!,
                    ).message)
              }
              disabled={
                !permissionService.canPurchaseItem(user, purchase.item)
                  .success ||
                saving ||
                !canAfford ||
                (purchase.item.isMilitary
                  ? !permissionService.canApproveMilitary(
                      user,
                      purchase.createdBy!,
                    ).success
                  : !permissionService.canApproveInfrastructure(
                      user,
                      purchase.createdBy!,
                    ).success)
              }
              onClick={() => {
                approve(purchase);
              }}
            >
              Approve{" "}
            </GeoButton>
            <GeoTooltip title="Approve this purchase and it will be available for placement on the map." />

            <GeoButton
              variant={"outlined"}
              size="small"
              color="primary"
              title={
                purchase.item.isMilitary
                  ? permissionService.canApproveMilitary(
                      user,
                      purchase.createdBy!,
                    ).message
                  : permissionService.canApproveInfrastructure(
                      user,
                      purchase.createdBy!,
                    ).message
              }
              disabled={
                saving ||
                (purchase.item.isMilitary
                  ? !permissionService.canApproveMilitary(
                      user,
                      purchase.createdBy!,
                    ).success
                  : !permissionService.canApproveInfrastructure(
                      user,
                      purchase.createdBy!,
                    ).success)
              }
              onClick={() => {
                decline(purchase);
              }}
            >
              Decline
            </GeoButton>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
}
