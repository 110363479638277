/** @jsxImportSource @emotion/react */
import Header from "../../components/header";
import ArticleComponent from "../../components/News/Article";
import Footer from "../../components/Footer";
import * as React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Article as ArticleType } from "../../my-api-client";
import { apiClient } from "../../services/apiClient.ts";
import { css } from "@emotion/react";

function ArticlePage() {
  const { id } = useParams();

  const { data: article, error } = useSWR<ArticleType, Error>(
    apiClient().allArticlesRetrieve.name,
    function () {
      return apiClient()
        .allArticlesRetrieve(parseInt(id || "0"))
        .then((res) => {
          return res.data;
        });
    },
  );

  if (error) return "Error loading article.";

  if (!article) {
    return <></>;
  }

  return (
    <>
      <div
        css={css`
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 20px;
        `}
      >
        <ArticleComponent article={article} />
      </div>
    </>
  );
}

export default ArticlePage;
