import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "@mui/material";

function Contact() {
  const email = "hello@geostratapp.com"; // Replace with your email address

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1">
        If you have any questions or need support, feel free to use the embedded
        chat or contact us at
        <Link href={`mailto:${email}`} style={{ marginLeft: "5px" }}>
          {email}
        </Link>
      </Typography>
    </Container>
  );
}

export default Contact;
