import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const GettingStarted = () => {
  return (
    <Container maxWidth="lg">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Following this guide will help you with everything needed to get started with running your first class.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Creating Your First Class</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Come here to read about current events happening in your world.
            Statements can be published here by the secretary of state for all
            nations to see.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Creating Your First Nations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Find information about the nations of your world here. Here the
            Comptroller is responsible for uploading your nations flag.
            Recommended height to width ratio is 2:3. The comptroller is also
            responsible for editing your nations constitution. On this page you
            will find your nations resources listed. Resources are used for
            developing your nation. Finally you will find information on how
            your nation is performing. These stats are influenced by the actions
            of your nation.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Adding Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Here your nation can manage relationships with other nations by
            making alliances or declaring wars. Alliances and declarations must
            be initialized by one party member and approved by another before
            being sent.
          </Typography>

          {/* Trade Alliances */}
          <Typography variant="h6">Trade Alliances</Typography>
          <Typography variant="body2" paragraph>
            Create an agreement with another nation on how your nations will
            agree to trade, import and export resources and goods. This alliance
            can be initiated, approved, and accepted by the Chief Financial
            Officer, Prime Minister, and Comptroller.
          </Typography>

          {/* Military Alliances */}
          <Typography variant="h6">Military Alliances</Typography>
          <Typography variant="body2" paragraph>
            Create an agreement with another nation on how your nations will
            agree to use their military resources. This alliance can be
            initiated, approved, and accepted by the Defense Minister, Prime
            Minister, and Secretary of State.
          </Typography>

          {/* Warning Declarations */}
          <Typography variant="h6">Warning Declarations</Typography>
          <Typography variant="body2" paragraph>
            If another nation is acting in a way that is not in the best
            interest of your nation, you can issue a warning to them. This
            warning can be initiated by the Defense Minister or Secretary of
            State. This warning can be approved by the Prime Minister, Defense
            Minister, or Comptroller.
          </Typography>

          {/* War Declarations */}
          <Typography variant="h6">War Declarations</Typography>
          <Typography variant="body2" paragraph>
            If a nation has ignored your warnings and you feel you must act you
            may declare war against that nation. This declaration can be
            initiated by the Defense Minister. This declaration can be approved
            by the Prime Minister or Secretary of State.
          </Typography>

          {/* Subpoena Declarations */}
          <Typography variant="h6">Subpoena Declarations</Typography>
          <Typography variant="body2" paragraph>
            A subpoena can be sent to force a nation to testify in court. This
            declaration can be initiated and approved by the Secretary of State,
            Prime Minister, Defense Minister, or Comptroller.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default GettingStarted;
