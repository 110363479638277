import * as React from "react";
import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "../link";
import Grid from "@mui/material/Grid";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useSearchParams } from "react-router-dom";
import { mutateUser } from "../../hooks/useUser";
import { Box, CircularProgress } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import axios from "axios";
import {GoogleLogin, useGoogleLogin} from "@react-oauth/google";
import {apiClient} from "../../services/apiClient.ts";

export default function SignIn() {
  const [queryParams, setQueryParams] = useSearchParams();
  const isReset = queryParams.get("reset") == "true";
  const googleLoginEnabled = queryParams.get("googleLogin") == "true";
  const [success, setSuccess] = useState(
    isReset ? "Password successfully reset. Please try logging in" : "",
  );
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const callGoogleLogin = async (access_token: string) => {
    const snack = enqueueSnackbar(
        <>
          Logging in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <CircularProgress />
        </>,
        {
          variant: "info",
          persist: true,
        },
    );
    const csrf = (await getCSRF()) as string;
    apiClient().allGoogleLoginCreate(access_token,
        {
          headers: {
            "X-CSRFToken": csrf,
          },
        }
        )
        .then((res) => {
          mutateUser("0").then(() => {
            navigate("/app/news");
          });
      setError("");
      enqueueSnackbar("Logged in", { variant: "success" });
    }).catch((err) => {
      setError("Wrong username or password.");
      console.error(err);
      enqueueSnackbar("Error signing in: " + err.response.data.detail, {
        variant: "error",
      });
      setError(err.response.data.detail);
    }).finally(() => {
      setSaving(false);
      closeSnackbar(snack);
    });
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await callGoogleLogin(tokenResponse.access_token);
    },
    onError: errorResponse => {
      console.log(errorResponse)
      enqueueSnackbar("Error signing in with google");
      setError("Error signing in with google");
    },
  });

  const getCSRF = () => {
    return fetch(import.meta.env.VITE_NODE + "/api/csrf/", {
      credentials: "include",
    })
      .then((res) => {
        return res.headers.get("X-CSRFToken");
      })
      .catch((err) => {
        console.error(err);
        return undefined;
      });
  };

  useEffect(() => {
    getSession();
  }, []);

  const getSession = () => {
    fetch(import.meta.env.VITE_NODE + "/api/session/", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.isAuthenticated) {
          //login2(data);
          navigate("/app/news");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const snack = enqueueSnackbar(
      <>
        Logging in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    const data = new FormData(event.currentTarget);
    event.preventDefault();
    const csrf = (await getCSRF()) as string;
    setSaving(true);
    apiClient().allLoginCreate({
      username: data.get("email") as string,
          password: data.get("password") as string,
    }, {
        headers: {
          "X-CSRFToken": csrf,
        },
    })
      .then(() => {
        mutateUser("0").then(() => {
          navigate("/app/news");
        });
        setError("");
        enqueueSnackbar("Logged in", { variant: "success" });
      })
      .catch((err) => {
        setError("Wrong username or password.");
        console.error(err);
        console.error(JSON.stringify(err));
        enqueueSnackbar("Error signing in: " + err.response.data.detail, {
          variant: "error",
        });
        setError(err.response.data.detail);
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              disabled={saving}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>

            <Button fullWidth variant="contained" onClick={() => {
              googleLogin();
            }}>Sign in with Google</Button>


            <Box>{success}</Box>
            <Box sx={{ color: "red" }}>
              <pre>{error}</pre>
            </Box>
            <Grid container>
              <Grid item xs>
                <Link href="/reset" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {/*
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
              */}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
