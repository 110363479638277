/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Comment } from "../../../my-api-client";
import {Box, ListItem, ListItemText, Typography } from "@mui/material";
import List from "@mui/material/List";
import Link from "../../link";
import Avatar from "@mui/material/Avatar";
import * as React from "react";

export default function Comments({ comments }: { comments: Comment[] }) {
  return (
    <>
      <Typography variant="h6" component="div" sx={{ mt: 4 }}>
        Comments
      </Typography>
      <div>{comments.length === 0 && "No Comments Yet"}</div>
      <List>
        {comments.map((comment) => {
          return (

              <ListItem key={comment.id}>
                <ListItemText
                  css={css`
                    white-space: pre-wrap;
                  `}
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                        <Link href={`/app/profile/${comment.createdBy.id}`} sx={{ display: "flex", alignItems: "center", marginRight: "5px" }} >
                        <Avatar sx={{ width: 32, height: 32, marginRight: "10px" }} src={comment.createdBy.imageUrl} />
                        {comment.createdBy.fullName}
                      </Link>
                      - {new Date(comment.createdAt).toLocaleDateString()}
                    </Box>
                  }
                  secondary={comment.content}
                />
              </ListItem>

          );
        })}
      </List>
    </>
  );
}
