import useSWR, {Fetcher, SWRResponse} from "swr";
import {Nations, Nations as NationsType} from "../my-api-client";
import { apiClient } from "../services/apiClient";


export const useNations = (): SWRResponse<Nations[], Error> => {

  const nationsFetcher: Fetcher<NationsType[]> = () => {
    return apiClient()
        .allNationsList()
        .then((res) => {
          return res.data;
        });
  };

  return useSWR<NationsType[], Error>(
      apiClient().allNationsList.name,
      nationsFetcher,
  );
};


