import useSWR, { Fetcher } from "swr";
import { UserClass } from "../../my-api-client";
import { apiClient } from "../../services/apiClient";
import useLogout from "../../hooks/useLogout";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import {userFetcher} from "../../utils.ts";

export const ProtectedRoute = ({ children }: any) => {
  const logout = useLogout();

  const {
    data: user,
    error,
    isLoading,
  } = useSWR<UserClass, Error>(apiClient().allUsersRetrieve.name, userFetcher);

  if (isLoading) {
    return <LoadingScreen fullScreen={true}/>;
  }

  if (!user) {
    // user is not authenticated
    logout();
    return;
  }
  return children;
};
