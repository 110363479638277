import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";

export default function GeoTooltip({ title }: any) {
  return (
    <Tooltip enterTouchDelay={0} title={title}>
      <IconButton>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}
