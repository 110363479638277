import React, {createContext, SetStateAction, useState} from "react";
import useSWR, { Fetcher } from "swr";
import { apiClient } from "../../services/apiClient";
import { UserClass } from "../../my-api-client";
import { userFetcher } from "../../utils";

export const InnerUserContext = createContext({
  user: {} as UserClass,
  chatLoaded: false,
  setChatLoaded: (value: SetStateAction<boolean>) => {},
  isLoading: true,
});
export default function UserContext(props: any) {
  const [loading, setLoading] = useState(true);
  const [chatLoaded, setChatLoaded] = useState(false);

  // eslint-disable-next-line prefer-const
  let { data, error, isLoading } = useSWR<UserClass, Error>(apiClient().allUsersRetrieve.name,
    userFetcher, {shouldRetryOnError: false}
  );

  if (error) {
    data = undefined;
    localStorage.removeItem("user");
  } else if (data && !isLoading) {
    localStorage.setItem("user", JSON.stringify(data));
  } else {
    const user = localStorage.getItem("user");
    if (user) {
      data = JSON.parse(user);
    }
  }

  /*useEffect(() => {
            if (!isLoading) setLoading(false);
          }, [isLoading]);*/

  /*if (!data && isLoading) {
      return <></>;
    }*/

  return (
    <InnerUserContext.Provider value={{ user: data as UserClass, isLoading, chatLoaded, setChatLoaded }}>
      {props.children}
    </InnerUserContext.Provider>
  );
}
