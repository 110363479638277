import React, { useState } from 'react';
import {TextField, Button, Alert} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import {apiClient} from "../../services/apiClient.ts";

const WaitlistForm = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    // @ts-ignore
    const handleSubmit = (event) => {
        event.preventDefault();

        // Basic email validation
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        // Clear error and simulate submission
        setError('');

        apiClient()
            .allWaitlistsCreate({ email: email })
            .then((res) => {
                enqueueSnackbar("Added to waitlist", { variant: "success" });
                setSubmitted(true);
                return res.data;
            })
            .catch((e) => {
                setError("Error adding to waitlist");
                enqueueSnackbar("Error adding to waitlist: " + e.toString(), {
                    variant: "error",
                });
                });
    };

    return (
            <>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Email Address"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={Boolean(error)}
                        helperText={error}
                        margin="normal"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={submitted}
                        sx={{ mt: 2 }}
                    >
                        {submitted ? 'Thank You!' : 'Join Waitlist'}
                    </Button>
                </form>
                {submitted && (
                    <Alert severity="success" sx={{ mt: 3 }}>
                        You've been added to the waitlist!
                    </Alert>
                )}
            </>
    );
};

export default WaitlistForm;
