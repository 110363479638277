/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { myMutate, useUser } from "../../hooks/useUser";
import {
    Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSWRConfig } from "swr";
import { permissionService } from "../../services/permissionService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { apiClient } from "../../services/apiClient";
import { Nations } from "../../my-api-client";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { css } from "@emotion/react";
import Typography from "@mui/material/Typography";

export default function Constitution({ nation }: { nation: Nations }) {
  const [saving, setSaving] = useState(false);
  const [originalConstitution, setOriginalConstitution] = useState(
    nation.constitution || "",
  );
  const [constitution, setConstitution] = useState(nation.constitution || "");
  const [editing, setEditing] = useState(false);
  const [viewing, setViewing] = useState(false);
  const user = useUser();
  const { mutate } = useSWRConfig();
  const handleClickOpen = () => {
    setEditing(true);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const save = async () => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    const res = await apiClient()
      .allNationsPartialUpdate(nation.id, { constitution: constitution })
      .then((res) => {
        enqueueSnackbar("Constitution Saved", {
          variant: "success",
        });
        myMutate(apiClient().allNationsRetrieve.name);
        myMutate(apiClient().allNationsList.name);
        setOriginalConstitution(res.data.constitution || "");
        setEditing(false);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error saving constitution: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
        setSaving(false);
      });
  };

  const cancel = async () => {
    setConstitution(nation.constitution || "");
    setEditing(false);
  };

  return (
    <>
      <Typography variant="body2" component="div" gutterBottom>
        {originalConstitution ? (
          <Button
            onClick={() => {
              setViewing(true);
            }}
          >
            View Constitution
          </Button>
        ) : (
          <Box sx={{pt: "6px", pb: "6px", pl: "8px", pr: "8px"}}>No Constitution</Box>
        )}
      </Typography>

      {permissionService.canEditConstitution(user, nation.id).success ? (
        <Button
          sx={{
            marginBottom: 1,
          }}
          variant="outlined"
          onClick={() => {
            setEditing(true);
          }}
        >
          Edit Constitution
        </Button>
      ) : (
        <></>
      )}

      <Dialog
        open={viewing}
        onClose={() => {
          setViewing(false);
        }}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={fullScreen}
      >
        <DialogTitle>Constitution</DialogTitle>
        <DialogContent
          style={{
            height: "90vh",
          }}
        >
          <DialogContentText>
            <div
              dangerouslySetInnerHTML={{ __html: originalConstitution }}
              css={css`
                overflow: auto;
                height: 100%;
              `}
            ></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setViewing(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editing}
        onClose={cancel}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={fullScreen}
      >
        <DialogTitle>Constitution</DialogTitle>
        <DialogContent
          style={{
            height: "90vh",
          }}
        >
          <DialogContentText></DialogContentText>
          <ReactQuill
            theme="snow"
            style={{
              height: `calc(100% - ${small ? "68" : "42"}px)`,
              width: "100%",
              maxWidth: "100%",
            }}
            value={constitution}
            onChange={setConstitution}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel} disabled={saving}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={save} disabled={saving}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
