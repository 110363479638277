/** @jsxImportSource @emotion/react */
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { adminApiClient, apiClient } from "../../../services/apiClient";
import { Item } from "../../../my-api-client";
import Grid from "@mui/material/Unstable_Grid2";
import { permissionService } from "../../../services/permissionService";
import {mutatePurchase, myMutate, useUser} from "../../../hooks/useUser";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { css } from "@emotion/react";
import StoreContent from "../storeContent.tsx";
import GeoButton from "../../GeoButton";

interface ItemInputProps {
  item: Item;
}

export default function StoreItem({ item }: ItemInputProps) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const purchase = (item: Item) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    apiClient()
      .allPurchasesCreate({
        itemId: item.id,
        offeringNationId: user.nation.id,
      })
      .then((res) => {
        enqueueSnackbar("Plans Submitted", { variant: "success" });
        mutatePurchase(apiClient().allPurchasesList.name, res.data);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error proposing plans: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  const gift = (item: Item) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    adminApiClient()
      .drfadminPurchasesGiftCreate(item.id)
      .then((res) => {
        enqueueSnackbar("Plans Submitted", { variant: "success" });
        mutatePurchase(apiClient().allPurchasesList.name, res.data);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error proposing plans: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  return (
    <Grid xs={12} sm={6} md={4} lg={3} key={item.id}>
      <Card>
        <StoreContent item={item} />

        <CardActions>
          <GeoButton
            variant={"outlined"}
            size="small"
            color="primary"
            title={
              permissionService.canPurchaseItem(user, item).message ||
              (item.isMilitary
                ? permissionService.canPurchaseMilitary(user).message
                : permissionService.canPurchaseInfrastructure(user).message)
            }
            disabled={
              !permissionService.canPurchaseItem(user, item).success ||
              saving ||
              (item.isMilitary
                ? !permissionService.canPurchaseMilitary(user).success
                : !permissionService.canPurchaseInfrastructure(user).success)
            }
            onClick={() => {
              purchase(item);
            }}
          >
            Purchase
          </GeoButton>
          {permissionService.isAdmin(user).success && (
            <GeoButton
              variant={"outlined"}
              size="small"
              color="primary"
              disabled={saving}
              onClick={() => {
                gift(item);
              }}
            >
              Gift
            </GeoButton>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}
