import Header from "../components/header";
import Diplomacy from "../components/Diplomacy";
import Footer from "../components/Footer";
import * as React from "react";

function DiplomacyPage() {
  return (
    <>
      <Diplomacy />
    </>
  );
}

export default DiplomacyPage;
