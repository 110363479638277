import { useEffect } from "react";

const Page = ({ title, children }: { title: string; children: any }) => {
  useEffect(() => {
    document.title = title || "";
  }, [title]);
  return children;
};

export default Page;
