import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";

const host = window.location.hostname;
if(host != "localhost") {
    ReactGA.initialize("G-767H2Q7V9Q");
}

export default function Analytics(props: any) {
    const location = useLocation();

    useEffect(() => {
        const host = window.location.hostname;
        if(host != "localhost") {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Custom Title" });
        }
    }, [location]);

    return (
        <>
            {props.children}
        </>
    );
}
