import { Link as MuiLink, LinkProps } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";

import React, { FC, forwardRef } from "react";

// add disabled prop to LinkProps
export interface LinkProps2 extends LinkProps {
  disabled?: boolean;
  href?: string;
}
const Link = forwardRef((props: LinkProps2, ref: React.ForwardedRef<any>) => {
  return (
    <MuiLink
      style={{ textDecoration: "none", cursor: props.disabled ? "default" : "pointer" }}
      onClick={(event) => props.disabled && event.preventDefault()}
      {...props}
      component={ReactRouterLink}
      to={props.href ?? "#"}
      ref={ref}
    />
  );
});

export default Link;
