import React from 'react';
import Box from '@mui/material/Box';


const ChangelogPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > :not(style)': {m: 1}, // spacing
            }}
        >
            <Box sx={{minWidth: "400px"}}>
                <p>March 18th, 2024</p>
                <ul>
                    <li>Add map option to display tile coordinates</li>
                </ul>
            </Box>
            <Box sx={{minWidth: "400px"}}>
                <p>March 14th, 2024</p>
                <ul>
                    <li>Added a changelog</li>
                    <li>Allowing longer comments on articles</li>
                    <li>Fix error when placing item on edge of screen</li>
                    <li>Update trade table status messages</li>
                </ul>
            </Box>
        </Box>
    );
};

export default ChangelogPage;
