import Header from "../../components/header";
import Nation from "../../components/Nation";
import Footer from "../../components/Footer";
import * as React from "react";

function NationPage() {
  return (
    <>
      <Nation />
    </>
  );
}

export default NationPage;
