import {useResetStore} from "react-admin";

export default function useLogoutAdmin() {
    // const queryClient = useQueryClient();
    const resetStore = useResetStore();

    const logout = () => {
        //resetStore();
        // queryClient.clear();
        return Promise.resolve();
    };

    return logout;
}
