import { Tooltip } from "@mui/material";
import React from "react";

// A button with a tooltip
export default function GeoWrapperTooltip({ title, children, inline }: any) {
  return (
    <Tooltip enterTouchDelay={0} title={title}>
      <span style={{ display: inline ? "inline" : "inline-block", cursor: "pointer" }}>
        {children}
      </span>
    </Tooltip>
  );
}
