import React from 'react';
import {Container, Typography, Box } from '@mui/material';
import WaitlistForm from "./waitlistForm.tsx";

const WelcomePage = () => {

    return (
        <Container maxWidth="sm">
            <Box mt={8} textAlign="center">
                <Typography variant="h3" gutterBottom>
                    Welcome to GeoStrat!
                </Typography>
                <Typography variant="h6" paragraph>
                    We're still working on some things. Join our waitlist for early access and to be the first to know when we launch.
                </Typography>
                <WaitlistForm/>
            </Box>
        </Container>
    );
};

export default WelcomePage;
