import { useEffect } from 'react';

const useScript = url => {
  useEffect(() => {
    let script = document.getElementById(url);
    if (!script) {
      script = document.createElement('script');

      script.id = url;
      script.src = url;
      script.async = true;

      document.body.appendChild(script);
    }

  }, [url]);
};

export default useScript;