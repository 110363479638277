import { Avatar } from "@mui/material";

interface NationImageProps {
  src: string | undefined | null;
}

export default function NationImage({ src, ...props }: NationImageProps) {
  return (
    src && (
      <img alt="flag" src={src || ""} width="75px" height={"50px"} {...props} />
    )
  );
}
