/** @jsxImportSource @emotion/react */
import { apiClient } from "../../services/apiClient";
import Alliances from "./alliances";
import { Box, Typography } from "@mui/material";

import AllianceModal from "./allianceModal";
import { permissionService } from "../../services/permissionService";
import { useUser } from "../../hooks/useUser";
import { TradeAlliance } from "../../my-api-client";
import { getButtonReceive, getButtonSend } from "./utils";

export default function TradeAlliances() {
  const user = useUser();

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Trade Alliances
      </Typography>
      <AllianceModal
        listCall={apiClient().allTradeAlliancesList.bind(apiClient())}
        allianceCreate={apiClient().allTradeAlliancesCreate.bind(apiClient())}
        allianceTypeTitle={"Trade"}
        disabled={permissionService.canInitiateTradeAlliance(user)}
      />
      <Alliances
        listCall={apiClient().allTradeAlliancesList.bind(apiClient())}
        buttonMapSend={getButtonSend(user, "Trade Alliance", "TradeAlliance")}
        buttonMapReceive={getButtonReceive(
          user,
          "Trade Alliance",
          "TradeAlliance",
        )}
      ></Alliances>
    </Box>
  );
}
