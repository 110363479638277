import News from "../../components/News";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import * as React from "react";

function NewsPage() {
  return (
    <>
      <News />
    </>
  );
}

export default NewsPage;
