import Header from "../components/header";
import Trade from "../components/Trade";
import Footer from "../components/Footer";
import * as React from "react";

function TradePage() {
  return (
    <>
      <Trade />
    </>
  );
}

export default TradePage;
