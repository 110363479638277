import { mutate } from "swr";
import {Purchase, UserClass} from "../my-api-client";
import { apiClient } from "../services/apiClient";
import { useContext } from "react";
import { InnerUserContext } from "../components/UserContext";

export const useUser = (): UserClass => {
  const { user } = useContext(InnerUserContext);
  return user;
};

export const myMutate = (id: string) => {
  return Promise.all([
    mutate(id.replace("bound ", "")),
    mutate(id),
    mutate("bound " + id),
  ]);
};

export const mutateUser = (id?: string) => {
  return myMutate(apiClient().allUsersRetrieve.name);
};

export const mutatePurchase = (key: string, result: Purchase | void) => {
  if (result) {
    mutate(key, () => {
      return result
    }, {
      populateCache: (updatedPurchase, purchases) => {
        // filter the list, and return it with the updated item
        if (purchases) {
          const filteredPurchases = purchases.filter((purchase: Purchase) => purchase.id !== updatedPurchase.id)
          return [updatedPurchase, ...filteredPurchases]
        } else {
            return [updatedPurchase]
        }
      },
      revalidate: false
    })
  }
}

export const mutateDeletePurchase = (key: string, result: Purchase | void) => {
  if (result) {
    mutate(key, () => {
      return result
    }, {
      populateCache: (updatedPurchase, purchases) => {
        // filter the list, and return it with the updated item
        if (purchases) {
          const filteredPurchases = purchases.filter((purchase: Purchase) => purchase.id !== updatedPurchase.id)
          return [...filteredPurchases]
        } else {
            return []
        }
      },
      revalidate: false
    })
  }
}

