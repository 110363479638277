/** @jsxImportSource @emotion/react */
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Trade, TradeStatusStatusEnum } from "../../../my-api-client";
import { apiClient } from "../../../services/apiClient";
import { permissionService } from "../../../services/permissionService";
import { myMutate, useUser } from "../../../hooks/useUser";
import GeoButton from "../../GeoButton";
import { css } from "@emotion/react";
import React, { useState } from "react";
import GeoTooltip from "../../GeoTooltip";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import LoadingScreen from "../../Loading/loadingScreen.tsx";

const STATUS = {
  PENDING_APPROVAL: "PENDING_APPROVAL",
  CANCELED: "CANCELED",
  COMPLETED: "COMPLETED",
};

interface TradesTableProps {
  trades: Trade[] | undefined,
  incoming: boolean,
  isLoading: boolean
}

export default function TradesTable({ trades, incoming, isLoading }: TradesTableProps) {
  const user = useUser();
  const [saving, setSaving] = useState(false);

  const outgoingStatusMap: { [name: string]: string } = {
    CREATED: "Awaiting your comptroller approval",
    SENDER_ACCEPTED: "Offer sent",
    RECEIVER_ACCEPTED: "Trade completed",
    SENDER_REJECTED: "Rejected by [confirmationUser]",
    RECEIVER_DECLINED: "Declined by [opposingUser]",
    SENDER_CANCELED: "Canceled by [confirmationUser]",
    RECEIVER_CANCELED: "Canceled by [opposingUser]",
  };

  const incomingStatusMap: { [name: string]: string } = {
    CREATED: "Awaiting your comptroller approval",
    SENDER_ACCEPTED: "Awaiting CFO approval",
    RECEIVER_ACCEPTED: "Trade completed",
    SENDER_REJECTED: "Rejected by [confirmationUser]",
    RECEIVER_DECLINED: "Declined by [opposingUser]",
    SENDER_CANCELED: "Canceled by [confirmationUser]",
    RECEIVER_CANCELED: "Canceled by [opposingUser]",
  };

  const getStatus = (row: Trade) => {
    const statusMap = incoming ? incomingStatusMap : outgoingStatusMap;
    return <>{statusMap[row.status!].replace('[confirmationUser]', row.confirmationUser || "team").replace('[opposingUser]', row.opposingUser || "team")}</>
  }

  const doApproveTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    apiClient()
      .allTradesSenderAcceptCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Approved", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error approving trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  const doExecuteTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesReceiverAcceptCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Completed", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        myMutate(apiClient().allNationsList.name);
        return res.data;
      })
      .catch((e) => {

        enqueueSnackbar("Error completing trade: " + e.response.data.detail.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  const doCancelTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesSenderCancelCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Cancelled", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error cancelling trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  const doDeclineTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesSenderDeclineCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Declined", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error declining trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
        setSaving(false);
      });
  };

  const doRejectTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesReceiverDeclineCreate(row.id)
      .then((res) => {
        myMutate(apiClient().allTradesList.name);
        myMutate(apiClient().allNationsList.name);
        enqueueSnackbar("Trade Rejected", { variant: "success" });
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error rejecting trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
        setSaving(false);
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nation</TableCell>
              <TableCell>Our Resources</TableCell>
              <TableCell>Their Resources</TableCell>
              <TableCell>Shipping Costs</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
                <TableRow>
                  <TableCell>
                    <LoadingScreen />
                  </TableCell>

                  </TableRow>
            }
            {trades &&
              trades.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell data-label={"Nation"}>
                    {incoming
                      ? row.offeringNation.name
                      : row.receivingNation.name}
                  </TableCell>
                  <TableCell data-label={"Our Resources"}>
                    {(incoming ? row.receivingResources : row.offeringResources).map((resource) => {
                      return (
                        <div
                          css={css`
                            display: flex;
                          `}
                          key={resource.id}
                        >
                          <img
                            css={css`
                              width: 20px;
                            `}
                            alt="image"
                            src={`${import.meta.env.VITE_CDN}${
                              resource.resourceType.icon
                            }`}
                          />{" "}
                          &nbsp; x {resource.total}
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell data-label={"Their Resources"}>
                    {(incoming ? row.offeringResources : row.receivingResources).map((resource) => {
                      return (
                        <div
                          css={css`
                            display: flex;
                          `}
                          key={resource.id}
                        >
                          <img
                            css={css`
                              width: 20px;
                            `}
                            alt="image"
                            src={`${import.meta.env.VITE_CDN}${
                              resource.resourceType.icon
                            }`}
                          />
                          &nbsp; x {resource.total}
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell data-label={"Shipping Cost"}>
                    <div
                      css={css`
                        display: flex;
                      `}
                    >
                      {" "}
                      1 x &nbsp;
                      <img
                        css={css`
                          width: 20px;
                        `}
                        alt="image"
                        src={`${import.meta.env.VITE_CDN}/Oil.png`}
                      />
                    </div>
                  </TableCell>
                  <TableCell data-label={"Date"}>
                    {new Date(row.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell data-label={"Status"}>
                    {getStatus(row)}
                  </TableCell>
                  <TableCell data-label={"Action"}>
                    {row.status === TradeStatusStatusEnum.SenderAccepted &&
                      incoming && (
                        <>
                          <GeoButton
                              css={css`
                              margin-right: 15px;
                            `}
                            title={
                              permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doExecuteTrade(row);
                            }}
                          >
                            Accept
                          </GeoButton>
                          <GeoButton
                            title={
                              permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doRejectTrade(row);
                            }}
                          >
                            Reject
                          </GeoButton>
                        </>
                      )}
                    {row.status === TradeStatusStatusEnum.Created &&
                      !incoming && (
                        <>
                          <GeoButton
                            css={css`
                              margin-right: 15px;
                            `}
                            title={
                              permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doApproveTrade(row);
                            }}
                          >
                            Approve
                          </GeoButton>
                          <GeoButton
                            title={
                              permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doDeclineTrade(row);
                            }}
                          >
                            Decline
                          </GeoButton>
                          <GeoTooltip
                            title={
                              "Approving this trade request will sent if to the target nation for approval.  Declining this request will make it void"
                            }
                          />
                        </>
                      )}
                    {row.status === TradeStatusStatusEnum.SenderAccepted &&
                      !incoming && (
                        <>
                          <GeoButton
                            title={
                              permissionService.canCancelOutgoingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canCancelOutgoingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doCancelTrade(row);
                            }}
                          >
                            Rescind Offer
                          </GeoButton>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
