import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import Cookies from "js-cookie";

export default function useLogoutZulip() {
    const navigate = useNavigate();
    const logout = () => {
        // @ts-ignore
        const zToken = window.zToken;
        if (zToken) {
            return fetch(zToken.site + "/logout/", {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }).then((res) => {
                //console.log("res", res);
            });
        } else {
            return Promise.resolve();
        }
    };

    return logout;
}
