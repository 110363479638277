import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { apiClient } from "../services/apiClient.ts";
import { useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";

export default function CompleteSubscriptionPage() {
  const [queryParams, setQueryParams] = useSearchParams();
  const sessionId = queryParams.get("session_id");

  useEffect(() => {
    // @ts-ignore
    apiClient().allCompleteSessionCreate(sessionId);
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Check Your Email
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          A confirmation email has been sent. Please check your inbox to
          continue. It may take a minute or two to arrive.
        </Typography>
      </Box>
    </Container>
  );
}
