import React, {useState} from "react";
import {
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Box,
} from "@mui/material";
import GeoWrapperTooltip from "../GeoWrapperTooltip";
import GeoButton from "../GeoButton";
import { useUser } from "../../hooks/useUser";
import PopulationChange from "./populationChange.tsx";
import {permissionService} from "../../services/permissionService.ts";

// Types for the props
export interface Stat {
  title: string;
  value: string;
  progress?: number;
  description: string;
}

interface NationStatsDisplayProps {
  stats: Stat[],
  nationId: number
}

// Main Component to Display Nation Stats as List
const NationStatsDisplay: React.FC<NationStatsDisplayProps> = ({ stats, nationId }) => {
  const user = useUser()
  const [open, setOpen] = useState(false);

  // Store housing stat in a variable
    const housingStat = stats.find(stat => stat.title === "Housing")

  return (
      <>

    <List>
      {stats.map((stat, index) => (
        <ListItem key={index} divider sx={{ justifyContent: "space-between" }}>
          { permissionService.isAdmin(user).success &&
          stat.title === "Population" && (
          <GeoWrapperTooltip placement="top">
            <ListItemText primary={
                (
                    <div>
                      <PopulationChange nationId={nationId} open={open} setOpen={setOpen} population={stat} housing={housingStat!} />
                      <GeoButton sx={{padding: 0}} variant={"text"} onClick={() => {
                        setOpen(true);
                      }} >
                        {stat.title}
                      </GeoButton>
                    </div>
                )
            } secondary={stat.value} />
          </GeoWrapperTooltip>
          ) || <GeoWrapperTooltip title={stat.description} placement="top">
            <ListItemText primary={
                stat.title
            } secondary={stat.value} />
          </GeoWrapperTooltip>
          }
          {stat.progress !== undefined && (
            <Box sx={{ width: "50%", ml: 2 }}>
              <LinearProgress variant="determinate" value={stat.progress} />
            </Box>
          )}
        </ListItem>
      ))}
    </List>
      </>
  );
};

export default NationStatsDisplay;
