/** @jsxImportSource @emotion/react */
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ResourceInput from "../ResourceInput";
import TradesTable from "./TradesTable";
import useSWR, { Fetcher } from "swr";
import {
  Nations,
  OfferingResourceRequest,
  ReceivingResourceRequest,
  Resource,
  ResourceType,
  Trade as TradeType, TradeAlliance,
  TradeRequest,
} from "../../my-api-client";
import { apiClient } from "../../services/apiClient";
import { myMutate, useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { css } from "@emotion/react";
import Container from "@mui/material/Container";
import GeoTooltip from "../GeoTooltip";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import GeoWrapperTooltip from "../GeoWrapperTooltip";
import GeoButton from "../GeoButton";

type NestedDictionary = { [key: string]: Resource };

export default function Trade() {
  const user = useUser();
  const [saving, setSaving] = useState(false);
  const [myTeam, setMyTeam] = useState<Nations>({} as Nations);
  const [selected, setSelected] = useState<Nations>({} as Nations);
  const [resources, setResources] = useState<{
    [key: number]: NestedDictionary;
  }>({});
  const { register, handleSubmit, reset } = useForm();

  const [teamDict, setTeamDict] = useState<{ [fieldName: string]: Nations }>(
    {},
  );
  const [errors, setErrors] = useState("");
  const [outgoingTrades, setOutgoingTrades] = useState<TradeType[]>();
  const [incomingTrades, setIncomingTrades] = useState<TradeType[]>();

  const nationsFetcher: Fetcher<Nations[]> = () => {
    return apiClient()
      .allNationsList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: nations, error } = useSWR<Nations[], Error>(
    apiClient().allNationsList.name,
    nationsFetcher,
  );

  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return apiClient()
        .allTradeAlliancesList()
        .then((res) => {
          return res.data;
        });
  };

  const { data: tradeAlliances } = useSWR<TradeAlliance[], Error>(
      apiClient().allTradeAlliancesList.name,
      tradeAlliancesFetcher,
  );

  const resourceTypesFetcher: Fetcher<ResourceType[]> = () => {
    return apiClient()
      .allResourceTypesList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: resourceTypes, error: resourceError } = useSWR<
    ResourceType[],
    Error
  >(apiClient().allResourceTypesList.name, resourceTypesFetcher);

  const tradesFetcher: Fetcher<TradeType[]> = () => {
    return apiClient()
      .allTradesList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: trades, isLoading } = useSWR<TradeType[], Error>(
    apiClient().allTradesList.name,
    tradesFetcher,
  );

  useEffect(() => {
    if (myTeam) {
      setOutgoingTrades(
        trades?.filter((x) => {
          return x.offeringNationId === myTeam.id;
        }),
      );
      setIncomingTrades(
        trades?.filter((x) => {
          return x.receivingNationId === myTeam.id;
        }),
      );
    }
  }, [trades, myTeam]);

  useEffect(() => {
    if (nations && user && user.nation) {
      const dict: { [key: number]: Nations } = {};

      let autoSelected: Nations | undefined;
      for (let i = 0; i < nations.length; i++) {
        const nation = nations[i];
        dict[nation.id] = nation;
        if (nation.id != user.nation.id && nation.hasTradePort) {
          autoSelected = nation;
        }
      }

      setTeamDict(dict);
      setMyTeam(dict[user.nation.id]);
      if (!selected.id && autoSelected) {
        setSelected(autoSelected);
      } else {
        setSelected(dict[selected.id])
      }
    }
  }, [nations, user]);

  useEffect(() => {
    if (!selected.id) {
      return;
    }
    const resources2: { [key: number]: NestedDictionary } = {};
    teamDict[user.nation.id].resources.forEach((resource) => {
      resources2[resource.resourceType.id] = { myTeam: resource };
    });
    selected.resources.forEach((resource) => {
      resources2[resource.resourceType.id] = {
        ...resources2[resource.resourceType.id],
        selected: resource,
      };
    });

    setResources(resources2);
  }, [selected, teamDict]);

  const onSubmit = async (data: any) => {
    const offeringResources: Array<OfferingResourceRequest> = [];
    const receivingResources: Array<ReceivingResourceRequest> = [];
    const postData: TradeRequest = {
      offeringNationId: myTeam.id,
      receivingNationId: selected.id,
      offeringResources: offeringResources,
      receivingResources: receivingResources,
    };
    let atLeast1 = false;
    atLeast1 = false;
    for (const key in data.resources1) {
      const resource = data.resources1[key];
      if (parseInt(resource) > 0) {
        atLeast1 = true;
        offeringResources.push({
          total: parseInt(resource),
          resourceTypeId: parseInt(key),
        });
      }
    }
    for (const key in data.resources2) {
      const resource = data.resources2[key];
      if (parseInt(resource) > 0) {
        atLeast1 = true;
        receivingResources.push({
          total: parseInt(resource),
          resourceTypeId: parseInt(key),
        });
      }
    }

    if (!atLeast1) {
      setErrors("Must select a resource to trade");
      return;
    } else {
      setErrors("");
    }
    setSaving(true);
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );

    apiClient()
      .allTradesCreate(postData)
      .then((res) => {
        myMutate(apiClient().allTradesList.name);
        reset();
        enqueueSnackbar("Trade Submitted", { variant: "success" });
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error submitting trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  const onSelectTeam = (event: any) => {
    setSelected(teamDict[event.target.value]);
  };

  if (!myTeam || !teamDict[myTeam.id]) {
    return <LoadingScreen />;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <Typography gutterBottom variant="h6" component="div">
        Request Trade
        <GeoTooltip title={"Trade resources with other nations"} />
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        css={css`
          max-width: 100%;
        `}
      >
        <Stack direction="column" spacing={2} justifyContent="center">
          <Stack direction="row" spacing={2}>
            <Select
              label="Nation"
              {...register("Nation")}
              disabled
              value={myTeam.id}
              sx={{
                height: 60,
                width: "50%",
              }}
            >
              {Object.values(teamDict).map((nation) => {
                return (
                  <MenuItem value={nation.id} key={"menu 1" + nation.id}>
                    {nation.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Box
              css={css`
                align-items: center;
                display: flex;
              `}
              sx={{
                height: 60,
                width: 20,
              }}
            ></Box>
            <Select
              label="Nation"
              onChange={onSelectTeam}
              value={selected.id}
              sx={{
                height: 60,
                width: "50%",
              }}
            >
              {Object.values(teamDict)
                .filter((nation) => {
                  return nation.id !== myTeam.id;
                })
                .map((nation) => {
                  return (
                        <MenuItem value={nation.id} key={"menu 2" + nation.id} disabled={!nation.hasTradePort}>
                            {nation.name}
                        </MenuItem>
                  );
                })}
            </Select>
          </Stack>

          {Object.values(resources)
            .filter(
              (resource) =>
                resource.myTeam != undefined && resource.selected != undefined,
            )
            .map((resource) => {
              return (
                <Stack
                  direction="row"
                  spacing={2}
                  key={"Resource " + resource.myTeam.id}
                >
                  <ResourceInput
                    key={"input1" + resource.myTeam.resourceType.id}
                    resource={resource.myTeam}
                    register={register}
                    name="resources1"
                  ></ResourceInput>
                  <Box
                    css={css`
                      align-items: center;
                      display: flex;
                    `}
                    sx={{
                      height: 60,
                    }}
                  >
                    <img
                      css={css`
                        width: 20px;
                      `}
                      alt="image"
                      src={`${import.meta.env.VITE_CDN}${
                        resource.myTeam.resourceType.icon
                      }`}
                    />
                  </Box>
                  <ResourceInput
                    key={"input2" + resource.selected.resourceType.id}
                    resource={resource.selected}
                    register={register}
                    name="resources2"
                  ></ResourceInput>
                </Stack>
              );
            })}
          <Box
            sx={{
              height: 20,
              width: "200px",
            }}
          >
            Shipping Costs
          </Box>
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{
                height: 60,
                width: "50%",
              }}
              variant="outlined"
              type="number"
              defaultValue="0"
              label={`Oil`}
              InputProps={{
                inputProps: {
                  disabled: true,
                },
              }}
            />
            <Box
              css={css`
                align-items: center;
                display: flex;
              `}
              sx={{
                height: 60,
              }}
            >
              <img
                css={css`
                  width: 20px;
                `}
                alt="image"
                src={`${import.meta.env.VITE_CDN}/Oil.png`}
              />
            </Box>
            <TextField
              sx={{
                height: 60,
                width: "50%",
              }}
              variant="outlined"
              type="number"
              defaultValue="0"
              label={`Oil`}
              InputProps={{
                inputProps: {
                  disabled: true,
                },
              }}
            />
          </Stack>

          { tradeAlliances?.find((alliance) => alliance.status == "RECEIVER_ACCEPTED" && (alliance.offeringNationId === selected.id || alliance.receivingNationId === selected.id) ) == undefined && (
              <Typography  color="warning.main">Warning: You do not have an active trade agreement with this nation</Typography >
          )}
          <Stack direction="row" spacing={2} justifyContent="right">
            {errors && <div>{errors}</div>}
            <GeoButton
              variant="outlined"
              type="submit"
              title={!myTeam.hasTradePort ? "MUST BUILD A TRADE PORT" : permissionService.canInitiateTrades(user).message}
              disabled={
                !myTeam.hasTradePort || !permissionService.canInitiateTrades(user).success || saving
              }
            >
              Request Trade
            </GeoButton>
          </Stack>
        </Stack>
      </form>
      <Container
        sx={{
          maxWidth: "100%",
        }}
      >
        {
          <>
            <Typography gutterBottom variant="h6" component="div">
              Outgoing Trades
            </Typography>
            <TradesTable trades={outgoingTrades} incoming={false} isLoading={isLoading} />
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 5 }}
            >
              Incoming Trades
            </Typography>
            <TradesTable trades={incomingTrades} incoming={true} isLoading={isLoading} />
          </>
        }
      </Container>
    </div>
  );
}
