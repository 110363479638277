/** @jsxImportSource @emotion/react */
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import Box from "@mui/material/Box";

export default function ArticleForm(props: {
  ogTitle: string;
  ogContent: string;
  onSave: (content: string, title: string) => void;
  onCancel: () => void;
  saving: boolean;
}) {
  const ogContent = props.ogContent;
  const ogTitle = props.ogTitle;
  const onSave = props.onSave;
  const onCancel = props.onCancel;
  const [content, setContent] = useState(ogContent);
  const [title, setTitle] = useState(ogTitle);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <TextField
          sx={{ mb: 2 }}
          label="Title"
          fullWidth
          inputProps={{ maxLength: 500 }}
          variant="outlined"
          required
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
        <TextField
          label="Body"
          required
          fullWidth
          margin="normal"
          multiline
          rows={14}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            variant="outlined"
            disabled={props.saving}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>{" "}
          <Button
            type="submit"
            variant="outlined"
            onClick={() => {
              onSave(title, content);
            }}
            disabled={!title || !content || props.saving}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
}
