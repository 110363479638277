/** @jsxImportSource @emotion/react */
import TradeAlliances from "./tradeAlliances";
import MilitaryAlliances from "./militaryAlliances";
import WarDeclarations from "./warDeclaration";
import WarningDeclarations from "./warningDeclaration";
import SubpoenaDeclarations from "./subpoenaDeclaration";
import { css } from "@emotion/react";

export default function Diplomacy() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          width: 100%;
          max-width: 1400px;
        `}
      >
        <TradeAlliances />
        <MilitaryAlliances />
        <WarDeclarations />
        <WarningDeclarations />
        <SubpoenaDeclarations />
      </div>
    </div>
  );
}
