import GeoWrapperTooltip from "../GeoWrapperTooltip";
import {Button} from "@mui/material";
import React from "react";
import GeoButton from "../GeoButton";

export default function ExecuteButton (props: any) {
    const item = props.item;
    const [saving, setSaving] = React.useState(false);

    return (
        <GeoButton
            title={item.title} key={item.text}
            disabled={item.disabled || saving}
            variant="outlined"
            onClick={() => {item.onClick(setSaving)}}
        >
            {item.text}
        </GeoButton>
    )
}