import Header from "../../components/header";
import Nations from "../../components/Nations";
import Footer from "../../components/Footer";
import * as React from "react";

function NationsPage() {
  return (
    <>
      <Nations />
    </>
  );
}

export default NationsPage;
