/** @jsxImportSource @emotion/react */
import useSWR, { Fetcher } from "swr";
import { apiClient } from "../../services/apiClient";
import { Purchase, PurchaseStatusEnum } from "../../my-api-client";
import Grid from "@mui/material/Unstable_Grid2";
import { css } from "@emotion/react";
import PurchaseCard from "./purchaseCard";
import { useUser } from "../../hooks/useUser";
import Typography from "@mui/material/Typography";
import * as React from "react";
import LoadingScreen from "../Loading/loadingScreen.tsx"; // Grid version 2

export default function Declined() {
  const user = useUser();
  const itemsFetcher: Fetcher<Purchase[]> = () => {
    return apiClient()
      .allPurchasesList()
      .then((res) => {
        return res.data;
      });
  };

  const {
    data: items2,
    error,
    isLoading,
  } = useSWR<Purchase[], Error>(
    apiClient().allPurchasesList.name,
    itemsFetcher,
  );

  const items = items2?.filter(
    (item) =>
        item.offeringNationId == user.nation.id &&
        (item.status == PurchaseStatusEnum.SenderCanceled ||
      item.status == PurchaseStatusEnum.SenderRejected)
  );

  if (error) return "Error loading nations.";

  if (!items || isLoading) {
    return <LoadingScreen />;
  }

  if (items && items.length === 0) {
    return (
      <Typography
        component="h1"
        variant="h5"
        css={css`
          text-align: center;
          margin-top: 20px;
        `}
      >
        No development plans have been declined
      </Typography>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      css={css`
        margin: 10px;
      `}
    >
      {items.map((item) => {
        return <PurchaseCard purchase={item} key={item.id} />;
      })}
    </Grid>
  );
}
