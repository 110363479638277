import Header from "../../components/header";
import Profile from "../../components/Profile";
import * as React from "react";
import Footer from "../../components/Footer";

function ProfilePage() {
  return (
    <>
      <Profile />
    </>
  );
}

export default ProfilePage;
