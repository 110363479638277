import { AllApi, Configuration, DrfadminApi } from "../my-api-client";
import Cookies from "js-cookie";
import globalAxios from 'axios';

export const apiClient = () => {
    // Add a response interceptor
    globalAxios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log(response);
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });
  return new AllApi(
    new Configuration({
      basePath: import.meta.env.VITE_NODE,
      baseOptions: {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        withCredentials: true,
      },
    }),
      import.meta.env.VITE_NODE,
      globalAxios
  );
};

export const adminApiClient = () => {
  return new DrfadminApi(
    new Configuration({
      basePath: import.meta.env.VITE_NODE,
      baseOptions: {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        withCredentials: true,
      },
    }),
  );
};
