import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import Development from "./index";
import Pending from "./pending";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Approved from "./approved.tsx";
import Declined from "./declined.tsx";
import Military from "./military.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [queryParams, setQueryParams] = useSearchParams();
  const tab = queryParams.get("tab") || "";

  const tabMap = {
      "": 0,
      pending: 2,
    approved: 3,
    declined: 4,
  };

    const [value, setValue] = React.useState(0);

    useEffect(() => {
        // @ts-ignore
        setValue((tab in tabMap) ? tabMap[tab] : 0);
    }, [queryParams]);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="development sections"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Infrastructure" {...a11yProps(0)} />
          <Tab label="Military" {...a11yProps(1)} />
          <Tab label="Pending" {...a11yProps(2)} />
          <Tab label="Approved" {...a11yProps(3)} />
          <Tab label="Declined" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Development />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Military />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Pending />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Approved />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Declined />
      </CustomTabPanel>
    </Box>
  );
}
