import * as React from "react";
import Help from "../components/Help";

function HelpPage() {
  return (
    <>
      <Help />
    </>
  );
}

export default HelpPage;
