import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function LoadingScreen({ fullScreen, ...props }: any) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%", // Full width of the viewport
                backgroundColor: "background.default", // Use theme's default background color
                position: "absolute", // Position it on top of other elements
                left: 0,
                zIndex: 9999, // Make sure it's on top
                height: fullScreen ? "calc(100vh - 64px)" : "auto"
            }}
            {...props}
        >
            <CircularProgress />
        </Box>
    );
}

export default LoadingScreen;
