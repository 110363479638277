import Header from "../components/header";
import BasicTabs from "../components/Development/basicTabs";
import Footer from "../components/Footer";
import * as React from "react";

function DevelopmentPage() {
  return (
    <>
      <BasicTabs />
    </>
  );
}

export default DevelopmentPage;
