import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useSWR, { mutate } from "swr";
import { Notification, UserClass } from "../../../my-api-client";
import { apiClient } from "../../../services/apiClient";
import { myMutate, useUser } from "../../../hooks/useUser";
import Link from "../../link";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";

interface NotificationDisplay {
  id: number;
  text: string;
  link: string;
  isRead: boolean;
  createdAt: string;
}

const linkMap = {
  Trade: "/app/trade",
  TradeAlliance: "/app/diplomacy",
  MilitaryAlliance: "/app/diplomacy",
  War: "/app/diplomacy",
  Subpoena: "/app/diplomacy",
  Warning: "/app/diplomacy",
  Purchase: "/app/development?tab=pending",
  Takeover: "/app/map",
};

const GetNotificationLink = (notification: Notification): string => {
  if (notification.modelName === "Purchase") {
    if (notification.notificationStatus === "PURCHASE_PLACED") {
      return "/app/map";
    }
    if (notification.notificationStatus === "SENDER_ACCEPTED") {
      return "/app/development?tab=approved";
    }
    if (notification.notificationStatus === "SENDER_REJECTED") {
      return "/app/development?tab=declined";
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return linkMap[notification.modelName];
};

const GetNotificationText = (
  notification: Notification,
  user: UserClass,
): string => {

  if (notification.notificationText) {
    return notification.notificationText;
  }

  const receivingNation = notification.receivingNation?.name;
  const offeringNation = notification.offeringNation.name;

  const modelNameMap = {
    Trade: "Trade",
    TradeAlliance: "Trade Alliance",
    MilitaryAlliance: "Military Alliance",
    War: "War",
    Subpoena: "Subpoena",
    Warning: "Warning",
    Purchase: "Purchase",
    Attack: "Attack",
    Takeover: "Takeover",
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tradeType: string = modelNameMap[notification.modelName];
  const direction =
    notification.offeringNation.id == user.nation.id ? "SENDING" : "RECEIVING";
  const notificationMap = {
    Purchase: {
      SENDING: {
        CREATED: `Your nation has proposed a project`,
        SENDER_ACCEPTED: `Your nation approved a project`,
        SENDER_REJECTED: `The purchase was declined`,
        PURCHASE_PLACED: "Your nation has placed a project",
        SENDER_CANCELED: ``,
        RECEIVER_ACCEPTED: ``,
        RECEIVER_DECLINED: ``,
        RECEIVER_CANCELED: ``,
      },
      RECEIVING: {
        CREATED: ``,
        SENDER_ACCEPTED: ``,
        SENDER_REJECTED: ``,
        SENDER_CANCELED: ``,
        RECEIVER_ACCEPTED: ``,
        RECEIVER_DECLINED: ``,
        RECEIVER_CANCELED: ``,
      },
    },
    Attack: {
      SENDING: {
        CREATED: `Your nation has proposed an attack against ${receivingNation}`,
        SENDER_ACCEPTED: `Your nation performed an attack against ${receivingNation}`,
        SENDER_REJECTED: `The attack against ${receivingNation} was declined`,
        PURCHASE_PLACED: "",
        SENDER_CANCELED: ``,
        RECEIVER_ACCEPTED: ``,
        RECEIVER_DECLINED: ``,
        RECEIVER_CANCELED: ``,
      },
      RECEIVING: {
        CREATED: ``,
        SENDER_ACCEPTED: `Your nation has been attacked by ${offeringNation}`,
        SENDER_REJECTED: ``,
        SENDER_CANCELED: ``,
        RECEIVER_ACCEPTED: ``,
        RECEIVER_DECLINED: ``,
        RECEIVER_CANCELED: ``,
      },
    },
  };
  const defaultNotificationMap = {
    Trade: {
      SENDING: {
        CREATED: `Your nation has proposed a ${tradeType} with ${receivingNation}`,
        SENDER_ACCEPTED: `Your nation has sent a ${tradeType} to ${receivingNation}`,
        SENDER_REJECTED: `Your nation has declined a ${tradeType} with ${receivingNation}`,
        SENDER_CANCELED: `Your nation has canceled a ${tradeType} with ${receivingNation}`,
        RECEIVER_ACCEPTED: `Your ${tradeType} has been accepted by ${receivingNation}`,
        RECEIVER_DECLINED: `Your ${tradeType} has been declined by ${receivingNation}`,
        RECEIVER_CANCELED: ``,
      },
      RECEIVING: {
        CREATED: ``,
        SENDER_ACCEPTED: `Your nation has received a ${tradeType} from ${offeringNation}`,
        SENDER_REJECTED: ``,
        SENDER_CANCELED: `${offeringNation} has rescinded a ${tradeType} offer`,
        RECEIVER_ACCEPTED: `Your nation has accepted a ${tradeType} from ${offeringNation}`,
        RECEIVER_DECLINED: `Your nation has declined a ${tradeType} from ${offeringNation}`,
        RECEIVER_CANCELED: ``,
      },
    },
  };
  if (
    tradeType in notificationMap &&
    notification.notificationStatus in
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notificationMap[tradeType][direction]
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return notificationMap[tradeType][direction][
      notification.notificationStatus
    ];
  } else if (
    "Trade" in defaultNotificationMap &&
    notification.notificationStatus in
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultNotificationMap["Trade"][direction]
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return defaultNotificationMap["Trade"][direction][
      notification.notificationStatus
    ];
  }

  return "";
};

const NotificationsDropdown: React.FC = () => {
  const user = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [notifications, setNotifications] = useState<NotificationDisplay[]>([]);

  const { data: notificationsData, error } = useSWR(
    apiClient().allNotificationsList.name,
    function () {
      return apiClient()
        .allNotificationsList()
        .then((res) => {
          return res.data.results;
        });
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  );

  useEffect(() => {
    const notifs: NotificationDisplay[] = [];
    notificationsData?.forEach((notification) => {
      const text = GetNotificationText(notification, user);
      if (text) {
        notifs.push({
          id: notification.id,
          text: text,
          link: GetNotificationLink(notification),
          isRead: notification.isRead,
          createdAt: new Date(notification.createdAt).toLocaleDateString(),
        });
      }
    });
    setNotifications(notifs);
  }, [notificationsData]);



  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    Promise.all(
      notifications
        .filter((notification) => !notification.isRead)
        .map((notification) => {
          return apiClient().allNotificationsMarkAsReadCreate(notification.id);
        }),
    ).then(() => {
      myMutate(apiClient().allNotificationsList.name);
    });
    notifications.forEach((notification) => {
      notification.isRead = true;
    });
    setNotifications([...notifications]);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge
          badgeContent={
            notifications.filter((notification) => !notification.isRead).length
          }
          color="error"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {notifications.length ? (
          notifications.map((notification) => (
            <MenuItem
              sx={{ backgroundColor: notification.isRead ? "#f0f0f0" : "" }}
              key={notification.id}
              onClick={handleClose}
              component={Link}
              href={notification.link}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body1">{notification.text}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {notification.createdAt}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>
            <Typography variant="body2">No Notifications</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default NotificationsDropdown;
