import { TradeAlliance, UserClass } from "../../my-api-client";
import { permissionService } from "../../services/permissionService";
import { apiClient } from "../../services/apiClient";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { mutate } from "swr";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import { myMutate } from "../../hooks/useUser.ts";
import {CircularProgress} from "@mui/material";
import React from "react";

const executeAlliance = (
  tradeAlliance: TradeAlliance,
  id: string,
  action: string,
  call: (
    id: number,
    options?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<TradeAlliance>>,
  setSaving: any
) => {
  const snack = enqueueSnackbar(
      <>
          Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <CircularProgress />
  </>,
  {
    variant: "info",
        persist: true,
  },
);
  setSaving(true);
  call
    .bind(apiClient())(tradeAlliance.id)
    .then((res) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      myMutate(apiClient()[`all${id}sList`].name);
      enqueueSnackbar(`${action}`, { variant: "success" });
    }).finally(() => {
    closeSnackbar(snack);
    setSaving(false);
  });
};

export const getButtonSend = (user: UserClass, title: string, id: string) => {

  return (alliance: TradeAlliance): { [nam: string]: any } => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canSend = permissionService[`canApproveOutgoing${id}`](
      user,
      alliance.createdBy,
    );
    const canCancel =
      `canCancelOutgoing${id}` in permissionService &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      permissionService[`canCancelOutgoing${id}`](user, alliance.createdBy);

    const senderAccept: any =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      apiClient()["all" + id + "sSenderAcceptCreate"];

    const senderDecline: any =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      apiClient()["all" + id + "sSenderDeclineCreate"];

    const senderCancel: any =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      apiClient()["all" + id + "sSenderCancelCreate"];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cancel: any = apiClient()["all" + id + "sCancelCreate"];

    return {
      CREATED: [
        {
          title: canSend.message,
          disabled: !canSend.success,
          onClick: (setSaving: any) => {
            executeAlliance(alliance, id, `${title} Accepted`, senderAccept, setSaving);
          },
          text: `Accept`,
        },
        {
          title: canSend.message,
          disabled: !canSend.success,
          onClick: (setSaving: any) => {
            executeAlliance(alliance, id, `${title} Declined`, senderDecline, setSaving);
          },
          text: `Decline`,
        },
      ],
      SENDER_ACCEPTED:
        ["TradeAlliance", "MilitaryAlliance"].indexOf(id) > -1
          ? [
              {
                title: canCancel.message,
                disabled: !canCancel.success,
                onClick: (setSaving: any) => {
                  executeAlliance(alliance, id, "Offer Rescinded", cancel, setSaving);
                },
                text: `Rescind Offer`,
              },
            ]
          : null,
      RECEIVER_ACCEPTED: [
        {
          title: canCancel.message,
          disabled: !canCancel.success,
          onClick: (setSaving: any) => {
            executeAlliance(alliance, id, `${title} ended`, senderCancel, setSaving);
          },
          text: `End Alliance`,
        },
      ],
    };
  };
};

export const getButtonReceive = (
  user: UserClass,
  title: string,
  id: string,
) => {
  return (alliance: TradeAlliance): { [nam: string]: any } => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canSend = permissionService[`canAcceptIncoming${id}`](
      user,
      alliance.createdBy,
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canCancel = permissionService[`canCancelIncoming${id}`](
      user,
      alliance.createdBy,
    );

    const receiverAccept: any =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      apiClient()["all" + id + "sReceiverAcceptCreate"];
    const receiverDecline: any =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      apiClient()["all" + id + "sReceiverDeclineCreate"];
    const receiverCancel: any =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      apiClient()["all" + id + "sReceiverCancelCreate"];

    return {
      SENDER_ACCEPTED: [
        {
          title: canSend.message,
          disabled: !canSend.success,
          onClick: (setSaving: any) => {
            executeAlliance(alliance, id, `${title} Approved`, receiverAccept, setSaving);
          },
          text: `Approve`,
        },
        {
          title: canSend.message,
          disabled: !canSend.success,
          onClick: (setSaving: any) => {
            executeAlliance(alliance, id, `${title} Denied`, receiverDecline, setSaving);
          },
          text: `Deny`,
        },
      ],
      RECEIVER_ACCEPTED: [
        {
          title: canCancel.message,
          disabled: !canCancel.success,
          onClick: (setSaving: any) => {
            executeAlliance(alliance, id, `${title} Ended`, receiverCancel, setSaving);
          },
          text: `End ${title}`,
        },
      ],
    };
  };
};
