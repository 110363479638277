import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LessonPlan = () => {
  return (
    <Container maxWidth="lg">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Suggested learning topics and goals for each week
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Week *</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Add students to the class and provide them with a nation and role.{" "}
            <br />
            Allow students to meet with their nation. <br />
            Come up with Nation names. <br />
            Design a nation flag <br />
            Work on constitution <br />
            chat with othe nations <br />
            select nations territory
            <br />
            place first village on map
            <br />
            form alliances
            <br />
            make trades
            <br />
            make purchases <br />
            respond to events <br />
            submit news articles <br />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default LessonPlan;
