/** @jsxImportSource @emotion/react */
import Comments from "../comments";
import AddComment from "../addComment";
import EditArticle from "../editArticle.tsx";
import { Article } from "../../../my-api-client";
import { Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "../../link";
import React, { useState } from "react";

export default function ArticleComponent({ article }: { article: Article }) {
  const [editing, setEditing] = useState(false);

  return (
    <Card sx={{ maxWidth: 800, margin: "auto", mt: 5, width: "100%" }}>
      <CardContent>
        {!editing && (
          <>
            <Link
              href={`/app/news/${article.id}`}
              color="inherit"
              disabled={article.id === 0}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: article.title,
                }}
              ></Typography>
            </Link>
            <Typography variant="body2" color="text.secondary">
              {new Date(article.createdAt).toLocaleDateString()} | By{" "}
              <Link href={`/app/profile/${article.createdBy.id}`}
                    disabled={article.id === 0 || article.createdBy.id === 1}
              >
                {article.createdBy.fullName}
              </Link>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{ mt: 2, whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: article.content,
              }}
            ></Typography>{" "}
          </>
        )}
          {
              article.id !== 0 && (<>
                  <EditArticle
                      article={article}
                      editing={editing}
                      setEditing={setEditing}
                  />
              <AddComment article={article} />
        <Comments comments={article.comments} />
                  </>
              )
          }
      </CardContent>
    </Card>
  );
}
