import React, {useContext, useEffect, useRef} from "react";
import Cookies from "js-cookie";
import { useUser } from "../hooks/useUser.ts";
import LoadingScreen from "../components/Loading/loadingScreen.tsx";
import {InnerUserContext} from "../components/UserContext";

function ZulipPage() {
  const { chatLoaded } = useContext(InnerUserContext);

  const user = useUser();
  const [site, setSite] = React.useState("");
  const [error, setError] = React.useState("");
  const ref = useRef<number>();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (ref.current == user.id) {
        return;
    }
    ref.current = user.id;
    (async () => {
      const res = await fetch(`${import.meta.env.VITE_NODE}/api/getToken/`, {
        // @ts-ignore
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        credentials: "include",
      }).then((res) => {
        return res.json();
      });
      // @ts-ignore
      window.zToken = res;
      const response = await fetch(res.site + "/accounts/login/jwt/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `token=${res.jwt}`,
      });

      //console.log(response);

      if (
        response.status === 0 ||
        response.status.toString().startsWith("2") ||
        response.status.toString().startsWith("3")
      ) {
        setSite(res.site);
      } else {
        setError("Unable to load chat");
      }
    })();
  }, [user]);

  return (
    <div
      id="community"
      style={{
        display: "none",
      }}
    >
      {error ? (
        <p>Unable to load chat</p>
      ) : !site || !chatLoaded ? (
          <LoadingScreen fullScreen={true} />
      ) : (
        <iframe
          src={site}
          title="Community"
          frameBorder="0"
          allowFullScreen
          style={{
            position: "fixed",
            overflow: "hidden",
            height: "calc(100% - 64px)",
            width: "100%",
            top: "64px",
          }}
          width="100%"
        ></iframe>
      )}
    </div>
  );
}

export default ZulipPage;
