import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useLogoutZulip from "./useLogoutZulip.ts";
import useLogoutAdmin from "./useLogoutAdmin.tsx";
import {googleLogout} from "@react-oauth/google";


export default function useLogout() {
  const navigate = useNavigate();
  const logoutZulip = useLogoutZulip();
  const logoutAdmin = useLogoutAdmin();

  const logout = (redirect = true) => {
    googleLogout();
    Promise.all([logoutZulip(),
    logoutAdmin(),
      localStorage.clear(),
      mutate(() => true, undefined, false),
    fetch(import.meta.env.VITE_NODE + "/api/logout/", {
      credentials: "include",
    })]).then(() => {
        // Cause a reload to clear cache and refetch variables
        window.location.pathname = "/login";
        //navigate("/login");
    });
  };

  return logout;
}
